import React, { useState, useEffect } from 'react';
// import { auth } from '../../firebase';

// import { app } from '../../firebase';
import {
  // signInWithEmailAndPassword, signInWithPopup,
  getAuth,
  sendSignInLinkToEmail
} from "firebase/auth";

import {useNavigate} from'react-router-dom'


import {useSelector} from  'react-redux'
import {Button} from 'antd'
// import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify'
import Header from '../../components/nav/Header';
import Footer from '../../components/nav/Footer';


// import PageTitle from '../../components/PageTitle';

//import RegistrationForm from '../../components/RegistrationForm'


const Register = () => {

  // const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  //const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { user } = useSelector(state => ({ ...state }))
  const auth = getAuth();

  useEffect(() => {
    if (user && user.token) {
      navigate('/')
    }
  }, [user])


  const handleChange = e => {
    setEmail(e.target.value)
   // console.log('email input:', e.target.value)
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

  //  console.log("Env--->",process.env.REACT_APP_REGISTER_REDIRECT_URL)
    // const config = {
    //   url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
    // //  url:'http://localhost:3000/register/complete',
    //   handleCodeInApp: true
    // }

    const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'https://www.example.com/finishSignUp?cartId=1234',
 url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
  // url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
  // This must be true.
  handleCodeInApp: true
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
}

// console.log('------------- auth --------------')
// console.log('------------- auth --------------', auth)

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast.success(`Email sent to ${email}. Please, click the link to complete the registration`)
        //save user email to local storage
        // console.log('---------------------- email ---------------', email)
        window.localStorage.setItem('emailForRegistration', email);
        //then clear the state in form:
        setEmail('');

      }).catch((error) => {
        const errorCode = error.code;
        console.log(errorCode)
        const errorMessage = error.message;
        console.log(errorMessage)
      })
  }

    const registrationForm = () =>  <form onSubmit={handleSubmit} className='register-form' >
        <div className="form__input-register p-3">
          <input
            type='email'
            id='email'
            className='form-control p-2 m-5'
            value={email}
            // placeholder="Enter your email"
           onChange={handleChange}
          />
          <label htmlFor="email" className="form__label">Your Email</label>
        </div>

        <br />
          <Button onClick={handleSubmit} className='btn--register ' >Register</Button>
      </form>




  return (
    <div className=''>

      <Header>
        <h1 className="heading-primary">
          <span className='heading-primary--main'>
            iDyaNova
          </span>
          <span className='heading-primary--sub'>
            No Distance is too far
          </span>
        </h1>

        {/* <a href="/login" className='btn btn--white btn--animated'>Explore the world</a> */}
      </Header>



      <div className="row-background-page">
       <div  className=" book m-5" style={{border:'.3rem white solid'}}>
         <div className="row-login-background-page" >
            <div className=" m-5 p-5" >
              <h1 className='heading--create-account-title'>Create An Account</h1>

              {registrationForm()}
            </div>
         </div>
       </div>


      </div>
      <Footer/>
    </div>
  )
}
export default Register;
