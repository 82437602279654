import React, { useState, useEffect } from 'react'
import {auth} from '../../firebase';
import { Badge, Menu } from 'antd'
import {
  AppstoreOutlined, SettingOutlined,
  UserAddOutlined, LoginOutlined,
  LogoutOutlined, ShopOutlined,
  ShoppingCartOutlined,
  // PlayCircleOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import {useNavigate, Link } from 'react-router-dom';
// import Logo from '../../images/dyagoonline.jpg';
import {getUserGeneralCart} from '../../functions/user'
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import Search from '../forms/Search';
import MenuIcon from '@mui/icons-material/Menu';

const { Item, SubMenu } = Menu;



const Header = ({productsLength, children}) => {
  const [current, setCurrent] = useState("Home");
  const [productList, setProductList] = useState(0)
  const [cart, setCart] = useState([])

  const [menuView, setMenuView] = useState(false)

  const dispatch = useDispatch();
   const navigate = useNavigate();

  const { user
    // , cart
  } = useSelector(state => ({ ...state }))
  // const { productList } = cart;
  //console.log('user-------->', user)

  const handleClick = (e) => {
    setCurrent(e.current)

  }

  // useEffect(() => {
//  handleProductList()
  // })

    useEffect(() => {
    const timeout = setTimeout(() => {
      getGenCartFromDB()

    }, 1000);
      return () => clearTimeout(timeout)

      // handleProductList();
    }
      // , [user]
    );

    const getGenCartFromDB = () => {
      // let cart = [];
      if (user) {
        getUserGeneralCart(user && user.token).then((res) => {
          if (res && res.data !== null) {
            const { productList, allProducts } = res.data;
            setProductList(productList || cart.productList)
          // console.log('>>>>>>>>>>>>>>>>>> header return from: UserGeneralCart; res.data <<<<<<<<<<<<<<<<<<<<<<<<<',allProducts)
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> header productList <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<',productList)
            // if (allProducts && productList) {
            //   // setGeneralCart(allProducts);
            //   // cart = allProducts? allProducts:  generalCart;
            //   cart = allProducts;
            //   cart.productList = productList;
            //    if (typeof window !== undefined) {
          //  if (localStorage.getItem("cart")) {
          //    cart = JSON.parse(localStorage.getItem('cart'));
          //  }
          // localStorage.setItem("cart", JSON.stringify(cart))
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> Header: cart <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<',cart)

          // dispatch({
          //   type: "ADD_TO_CART",
          //   payload: cart
          // })
        // }

            }
        })

      }
    //  console.log('----------------------------------------------------- what: all Products:: ?????------------------------------', allProducts)

    // })
  }

  const handleProductList = () => {
    let cart = [];
    if (typeof window !== 'undefined') {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem('cart'));
        // console.log('-------------HEADER-----cart--------- what: all Products:: ?????---------------------', cart)
      }

      setCart(cart)
      // cart = cart && cart.filter((p) =>
      //   p && p.cartProduct && p.cartProduct.length !== 0
      // )

      //  console.log('----------------Header--------------cart--------- getUserGeneralCart:: ?????---------------------------', cart)


      // localStorage.setItem("cart", JSON.stringify(cart))
    }
  }

  const logout = () => {
    // firebase.auth().signOut()
    // is it auth or auth()?
    auth.signOut()

    dispatch({
      type: "LOGOUT",
      payload:null
    })

    navigate('/login')
  }





  return (

    <div className='new-row-new-arrivals'>

      {user && (
     <div className="header-image">
        <div className='header-image__logo-box'>
          <div className='header-image__logo' >
            <h1 className="new-heading-primary">
              <span className='new-heading-primary--main-logo-domain-logged-in'>iDYANOVA</span>
              {/* <span className='new-heading-primary--sub-logo-slogen-logged-in'>No Distance is too far</span> */}
              <span className='new-heading-primary--sub-logo-slogen-logged-in'>Relentless</span>
            </h1>
          </div>
        </div>
        {children}
      </div>
      )}

      {!user && (
         <div className="header-image">
          <div className="header-image__text-box">
            <h1 className="new-heading-primary">
              <span className='new-heading-primary--header-domain-logged-out'>iDYANOVA</span>
              {/* <span className='new-heading-primary--header-slogen-logged-out'>No Distance is too far</span> */}
              <span className='new-heading-primary--header-slogen-logged-out'>Relentless</span>
            </h1>
            <a href="/login" className="login-btn login-btn--white btn--animated u-margin-top-big">Reach the World</a>
          </div>
        </div>
      )}

      <div className='new-header-menu-phone' >
        <div className='new-header-menu-phone-item' >
          <MenuIcon
            style={{
              fontSize: '7rem', cursor: 'pointer', margin: '.1rem auto .1rem 1rem',
              paddingBottom: '-1rem',
            }}

              onClick={() => setMenuView(!menuView)}
            />
          <Menu selectedKeys={[current]}
            onClick={handleClick} mode='horizontal'
            style={{ backgroundColor: 'transparent' }}
            theme="filled"
          >
            {/* <Item className="new-header-menu-phone-item new-header-menu-phone-item--search  " > */}
           <Item className={menuView ? 'new-header-menu-phone-item new-header-menu-phone-item--search-hidden'
              : 'new-header-menu-phone-item new-header-menu-phone-item--search'}
            >
              <span  >
                <Search
                  menuView={menuView}
                />
              </span>
            </Item>
          </Menu>

          {menuView && (
            <div className='new-header-menu-phone__viewed' >
                <Menu selectedKeys={[current]}
                  onClick={handleClick} mode='horizontal'
                  style={{ backgroundColor: 'transparent', marginTop:'0',marginBottom:'-3rem' }}
                  theme="filled"
                >
                  <Item key="home"  style={{color:'white', marginLeft:'4rem', padding:'0'}} icon={<AppstoreOutlined />}  >
                  <Link to='/' style={{ color: 'white', fontSize: '3rem', marginLeft: '-1.5rem', padding: '0'}} >Home</Link>
                  </Item>

                  <Item key="shop" style={{color:'white', marginLeft:'4rem', padding:'0'}} icon={<ShopOutlined />}  >
                    <Link to='/shop' style={{color:'white', fontSize:'3rem',marginLeft: '-1.5rem', padding:'0'}} >Shop</Link>
                  </Item>
                  <Item key="videos"style={{color:'white', marginLeft:'4rem', padding:'0'}} icon={<VideoCameraOutlined />} >
                    <Link to='/videos' style={{color:'white', fontSize:'3rem', marginLeft: '-1.5rem', padding:'0'}}>Videos</Link>
                  </Item>

                {user && (
                  <Item key="wislist"style={{color:'white', marginLeft:'4rem', padding:'0'}} icon={<ShopOutlined />}

                  >
                    <Link to='/user/wishlist' style={{color:'white', fontSize:'3rem', marginLeft: '-1.5rem', padding:'0'}}>
                      Wishlist
                    </Link>
                  </Item>
                  )}

                <Item key="cart"style={{color:'white', marginLeft:'4rem', padding:'0'}} icon={<ShoppingCartOutlined />}

                >
                    <Link to='/cart' style={{color:'white', fontSize:'3rem', marginLeft: '-1.5rem', padding:'0'}}>
                      Cart
                      <Badge count={productsLength || productList } offset={[3,-15]}  >
                      </Badge>
                    </Link>
                  </Item>

                </Menu>
            </div>
          )}

                  {/* {!user && (
                    <Item   key="register" icon={<UserAddOutlined />} className="new-header-menu-item float-right">
                      <Link to='/register' className='new-header-menu-phone-item'>Register</Link>
                    </Item>
                  )}


                  {!user && (
                    <Item key="login" icon={<LoginOutlined />} className="new-header-menu-item float-right">
                      <Link to='/login' className='new-header-menu-phone-item'>Login</Link>
                    </Item>
                  )} */}

            {menuView && (
            <div className='new-header-menu-phone__viewed-profile' >
                <Menu selectedKeys={[current]}
                  onClick={handleClick} mode='horizontal'
                style={{
                  backgroundColor: 'transparent',
                  marginTop: '-3rem', marginBottom: '-3rem', color: '#063970'
                }}
                  theme="filled"
                >

                  {user && (
                    <SubMenu
                      key="subscriber"
                      icon={<SettingOutlined />}
                      title={user.name && user.name.split(' ')[0]}
                      className="new-header-menu-item__profile float-right"
                    >
                      {/* title={user.email && user.email.split('@')[0]} */}

                      {/* <Item className='new-header-menu-item'>
                        <Link to='/user/history'>Dashboard</Link>
                      </Item> */}
                      <Item className=''>
                        <Link to='/user/profile' className='sub-menu-text' >
                          {user.name && user.name.split(' ')[0]}'s Profile
                        </Link>
                      </Item>
                      <Item className=''>
                        <Link to='/user/history' className='sub-menu-text'>Order History</Link>
                      </Item>
                      <Item  icon={<LogoutOutlined />} onClick={logout} className='sub-menu-text'>
                        Logout
                      </Item>
                    </SubMenu>
                  )}
                  {user && user.isSeller && (
                    <SubMenu
                      key="isSeller"
                      icon={<SettingOutlined />}
                      title='Seller'
                      className="new-header-menu-item__profile float-right"
                    >
                      <Item className='new-header-menu-item'>
                        <Link to='/seller/dashboard' className='sub-menu-text'>Seller's Profile</Link>
                      </Item>
                      <Item className='new-header-menu-item'>
                        <Link to='/seller/detail-order-list' className='sub-menu-text'>Order Detail List</Link>
                      </Item>
                      <Item className='new-header-menu-item'>
                        <Link to='/seller/productlist' className='sub-menu-text'>Products</Link>
                      </Item>
                      <Item className='new-header-menu-item'>
                        <Link to='/seller/orderlist' className='sub-menu-text'>Orders</Link>
                      </Item>

                      <Item
                        icon={<LogoutOutlined />}
                        onClick={logout} className='sub-menu-text'>Logout
                      </Item>
                    </SubMenu>
                  )}

                  {user && user.role === "admin" && (
                    <SubMenu
                      key="admin"
                      icon={<SettingOutlined />}
                      title='Admin'
                      className="new-header-menu-item__profile float-right"
                    >
                      <Item>
                        <Link to='/admin/dashboardanalysis'className='sub-menu-text'>Dashboard</Link>
                      </Item>
                      <Item>
                        <Link to='/admin/products'className='sub-menu-text'>Products</Link>
                      </Item>
                      <Item>
                        <Link to='/admin/orderlist'className='sub-menu-text'>Orders</Link>
                      </Item>
                      <Item>
                        <Link to='/admin/userlist'className='sub-menu-text'>Users</Link>
                      </Item>
                      {/* <Item>
                        <Link to='/support'>ChatPage</Link>
                      </Item> */}
                      <Item
                        icon={<LogoutOutlined />} className='sub-menu-text'
                        onClick={logout}>Logout
                      </Item>
                    </SubMenu>
                )}


          {!user && (
                  <Item key="register" icon={<UserAddOutlined />}
                    className="new-header-menu-item float-right">
              <Link to='/register' className='new-header-menu-item__profile sub-menu-text'>Register</Link>
            </Item>
          )}


          {!user && (
              <Item key="login"
                    icon={<LoginOutlined />} className="new-header-menu-item float-right">
              <Link to='/login' className='new-header-menu-item__profile sub-menu-text'>Login</Link>
            </Item>
          )}


                </Menu>
            </div>
          )}
        </div>
      </div>

       <div className='new-header-menu'  >
        <Menu selectedKeys={[current]}
          onClick={handleClick} mode='horizontal'
          style={{ backgroundColor: 'transparent' }}
          theme="filled"
        >
          <Item key="home" className='new-header-menu-item' icon={<AppstoreOutlined />}  >
            <Link to='/' className='new-header-menu-item'  >Home</Link>
          </Item>

          <Item key="shop" icon={<ShopOutlined />} className='new-header-menu-item ' >
            <Link to='/shop'className='new-header-menu-item' >Shop</Link>
          </Item>
          <Item key="videos" icon={<VideoCameraOutlined />} className='new-header-menu-item'>
            <Link to='/videos' className='new-header-menu-item'>Videos</Link>
          </Item>

        {  user && (<Item key="wislist" icon={<ShopOutlined />} className='new-header-menu-item'>
            <Link to='/user/wishlist' className='new-header-menu-item'>
              Wishlist
            </Link>
          </Item>
          )}

          <Item key="cart" icon={<ShoppingCartOutlined />} className='new-header-menu-item'>
            <Link to='/cart' className='new-header-menu-item'>
              Cart
              <Badge count={productsLength || productList } offset={[3,-15]}  >
              </Badge>
            </Link>
          </Item>

          <Item className="new-header-menu-item new-header-menu-item--search  " >
              <span  >
            <Search   />
          </span>
          </Item>

          {!user && (
            <Item   key="register" icon={<UserAddOutlined />} className="new-header-menu-item float-right">
              <Link to='/register' className='new-header-menu-item'>Register</Link>
            </Item>
          )}


          {!user && (
            <Item key="login" icon={<LoginOutlined />} className="new-header-menu-item float-right">
              <Link to='/login' className='new-header-menu-item'>Login</Link>
            </Item>
          )}

          {user && (
            <SubMenu
              key="subscriber"
              icon={<SettingOutlined />}
              title={user.name && user.name.split(' ')[0]}
              className="new-header-menu-item float-right"
            >
              {/* title={user.email && user.email.split('@')[0]} */}

              {/* <Item className='new-header-menu-item'>
                <Link to='/user/history'>Dashboard</Link>
              </Item> */}
              <Item className=''>
                <Link to='/user/profile' className='sub-menu-text' >
                  {user.name && user.name.split(' ')[0]}'s Profile
                </Link>
              </Item>
              <Item className=''>
                <Link to='/user/history' className='sub-menu-text'>Order History</Link>
              </Item>
              <Item  icon={<LogoutOutlined />} onClick={logout} className='sub-menu-text'>
                Logout
              </Item>
            </SubMenu>
          )}
          {user && user.isSeller && (
            <SubMenu
              key="isSeller"
              icon={<SettingOutlined />}
              title='Seller'
              className="new-header-menu-item float-right"
            >
              <Item className='new-header-menu-item'>
                <Link to='/seller/dashboard' className='sub-menu-text'>Seller's Profile</Link>
              </Item>
              <Item className='new-header-menu-item'>
                <Link to='/seller/detail-order-list' className='sub-menu-text'>Order Detail List</Link>
              </Item>
              <Item className='new-header-menu-item'>
                <Link to='/seller/productlist' className='sub-menu-text'>Products</Link>
              </Item>
              <Item className='new-header-menu-item'>
                <Link to='/seller/orderlist' className='sub-menu-text'>Orders</Link>
              </Item>

              <Item
                icon={<LogoutOutlined />}
                onClick={logout} className='sub-menu-text'>Logout
              </Item>
            </SubMenu>
          )}

          {user && user.role === "admin" && (
            <SubMenu
              key="admin"
              icon={<SettingOutlined />}
              title='Admin'
              className="new-header-menu-item float-right"
            >
              <Item>
                <Link to='/admin/dashboardanalysis'className='sub-menu-text'>Dashboard</Link>
              </Item>
              <Item>
                <Link to='/admin/products'className='sub-menu-text'>Products</Link>
              </Item>
              <Item>
                <Link to='/admin/orderlist'className='sub-menu-text'>Orders</Link>
              </Item>
              <Item>
                <Link to='/admin/userlist'className='sub-menu-text'>Users</Link>
              </Item>
              {/* <Item>
                <Link to='/support'>ChatPage</Link>
              </Item> */}
              <Item
                icon={<LogoutOutlined />} className='sub-menu-text'
                onClick={logout}>Logout
              </Item>
            </SubMenu>
          )}


        </Menu>
      </div>

      {/* <div className='new-header-menu-phone' >
        <Menu selectedKeys={[current]}
          onClick={handleClick} mode='horizontal'
          style={{ backgroundColor: 'transparent' }}
          theme="filled"
        >
          <Item className="new-header-menu-phone-item new-header-menu-phone-item--search  " >
            <span  >
              <Search   />
            </span>
          </Item>
        </Menu>
      </div> */}


    </div>
  )
}

export default Header;

