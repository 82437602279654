import React, { useState, useEffect } from 'react';
// import { auth } from '../../firebase';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import {useNavigate} from'react-router-dom'
import { Button } from 'antd';
import {ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Header from '../../components/nav/Header';
import Footer from '../../components/nav/Footer';
// import LoadingBox from '../../components/LoadingBox';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');

  const { user } = useSelector(state => ({ ...state }));

  const auth = getAuth();
   const navigate = useNavigate();

  useEffect(() => {
    if (user && user.token) {
      navigate('/')
    }
  }, [ user])

  const handleSubmit = async(e) => {
    e.preventDefault()
    setLoading(true);

     const actionCodeSettings = {
      url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
      handleCodeInApp: true
    }

    // await auth.sendPasswordResetEmail(email, config)
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setEmail('')
        setLoading(false)
        toast.success(`Check your email: ${email} for password reset link`)
      })
      .catch((error) => {
        toast.error('Sorry, it is invalid email!')
        setLoading(false)
      })

  }


  return (
    <div>
       <Header>
        <h1 className="heading-primary">
              <span className='heading-primary--main'>iDYANOVA
              </span>
              <span className='heading-primary--sub'>Best shopping online</span>
            </h1>

            <a href="/login" className='btn btn--white btn--animated'>Explore the world</a>
      </Header>
      <div className="row-background-page">
       <div className="book  m-5 ">
        <div className="row-background-page">
       <div className='m-5 p-5'>
          {loading ?
          (<div className="spin-reset-password text-center">Loading ...</div>)
          : (<h1 className="heading text-danger text-center">Forgot Password</h1>)
        }
        <form onSubmit={handleSubmit} className='form'>
          <div className="form__input-forgot-password p-5">
            <input className="form-control p-2 m-5"
              type='email' value={email}
              // placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <label htmlFor="email" className="form__label">Your Email</label>
          </div>
                  <br />
                  {/* <div className='background-white'> */}
            <ToastContainer  />
                  {/* </div> */}
          <Button
                    onClick={handleSubmit}
                      // className="heading-primary--sub-5
                      // button-card--animated u-margin-top-big"
             className='btn--forgot-password' type='primary' shape='round' block  size='large'
             disabled={!email}>
                    Submit
          </Button>
        </form>
       </div>
       </div>
       </div>
      </div>
      <Footer/>
    </div>
  )
}

export default ForgotPassword

